import React from "react";
import { SEO } from "wmk-lib";
import { baseUrl, siteTitle } from "../vars/ThemeOptions";
import { graphql } from "gatsby";
import Layout from "../components/layout/LandingPages/HappyHour/HappyHourLayout";
import Thanks from "../components/layout/LandingPages/Thanks";

export default ({ pageContext, data }) => {
  const { slug, campaign } = pageContext;
  const { thanks } = data;
  return (
    <Layout>
      <SEO
        title={""}
        path={`/${campaign.slug}/${slug}`}
        slug={slug}
        //description={desc}
        ogImage={null}
        twitterImage={null}
        //twitterHandle={twitter}
        siteTitle={siteTitle}
        baseUrl={baseUrl}
      />
      {campaign.slug === "happy-hour" || campaign.slug === "client-member" ? (
        <Thanks pageData={thanks} />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query tyQuery($title: String!) {
    thanks: contentfulCrmThankYou(title: { eq: $title }) {
      ...CrmThankYou
    }
  }
`;
