import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BubbleBackground from "../../../testing/BubbleBackground/BubbleBackground";
import { CallToAction } from "../../../testing/CallToAction";
import { H1, H2 } from "../../../testing/typography";

const Thanks = ({ pageData }) => {
  const [pageSize, setPageSize] = useState();

  const wrapRef = useRef();

  const { h1, h2, cta } = pageData;
  const to = cta.url ? cta.url : "/" + cta.ref.slug;

  useEffect(() => {
    const page = wrapRef.current;
    const resize = () => {
      setPageSize(page.getBoundingClientRect());
    };
    resize();
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [pageData]);
  return (
    <React.Fragment>
      <BubbleBackground pageSize={pageSize} />
      <Container ref={wrapRef} fluid style={{ minHeight: "75vh" }}>
        <Container>
          <Row className="flex-column">
            <Col>
              <H1 center>{h1}</H1>
            </Col>
            <Col>
              <H2 center>{h2}</H2>
            </Col>
            <Col style={{ textAlign: "center", padding: "3vh" }}>
              <CallToAction to={to} target={cta.target}>
                {cta.text} 
              </CallToAction>
            </Col>
          </Row>
        </Container>
      </Container>
    </React.Fragment>
  );
};

export default Thanks;
