import React from "react";
import styled from "styled-components";
import { WMKLink } from "wmk-lib";
import { colors } from "../vars/palette";

const CTA = styled(WMKLink)`
  padding: 2vh 4vh;
  background: ${colors.hex("primary")};
  transition: all 0.3s ease;
  color: ${colors.hex("white")};
  border-radius: 0.5vh;
  box-shadow: 0px 2px 6px ${colors.rgba("black", 0.21)};
  text-transform: uppercase;
  &:hover {
    background: ${colors.hex("secondary")};
    transition: all 0.3s ease;
    text-decoration: none;
    box-shadow: 0px 2px 6px ${colors.rgba("black", 0.42)};
  }
`;

export const CallToAction = ({ to, target, children }) => {
  return (
    <CTA to={to} target={target}>
      {children}
    </CTA>
  );
};
